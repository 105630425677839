.alert-top {
    z-index: 7001;
    position: fixed;
    width: 100%;
    border-radius: 0;
    text-align: center;
    transition: all 0.5s;
    margin-bottom: 0;
    top: 0;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
