@charset "UTF-8";
@import 'variables';

// Invisible Google Captcha default setup
@import "googleCaptcha";

/*Custom start*/
.white-bg {
    background-color: $color-base-light;
}

.violet-bg {
    background-color: #371866;
}

.light-green-bg {
    background-color: $primary-theme-color;
}

.dark-green-bg {
    background-color: $primary-theme-color-dark;
}

.light-gray-bg {
    background-color: $secondary-theme-color-light;
}

.gray-1-bg {
    background-color: $secondary-theme-color-lighter !important;
}

.grey-bg {
    background-color: $secondary-theme-color-dark;
}

.very-dark-grey-bg {
    background-color: $secondary-theme-color-darker;
}

/* font colors */
.white {
    color: $color-base-light;
}

.dark-green {
    color: $primary-theme-color-dark;
}

.gray {
    color: $secondary-theme-color-dark;
}

.dark-grey {
    color: $body-color;
}

.very-dark-grey {
    color: $secondary-theme-color-darker;
}

/* icons */
.icon-marker,
.icon-clock,
.icon-education {
    &:before {
        color: $primary-theme-color-dark;
    }
}
/* icons end */

/* style of major elements | START */
body {
    overflow-x: hidden;
    color: $body-color;
    min-height: 100vh;

    @media (max-width: 1024px) and (orientation: landscape) {
        min-height: 163vh;
    }
}

.jobOfferReadMore {
    border-top-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.hovereffect, .panelImgTop {
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.panelBody, .textRelatedItem, .textRelatedNews {
    border-bottom-right-radius: 20px !important;
}
/* style of major elements | END */

/* fonts */
@font-face {
    src: url(/fonts/SourceSansPro-Regular.ttf);
    font-family: SourceSansProRegular;
}

@font-face {
    src: url(/fonts/SourceSansPro-Bold.ttf);
    font-family: SourceSansProBold;
}

.SourceSansProRegular {
    font-family: SourceSansProRegular;
}

.SourceSansProBold {
    font-family: SourceSansProBold;
}

#banner-image {
    height: 220px;
    overflow: hidden;

    @media (min-width: 1024px) {
        height: 480px;
    }

    img {
        height: 100%;

        @media (min-width: 1800px) {
            min-width: 100%;
        }
    }
}

/*mobile main menu lines*/
.navbar-header {
    @media (min-width: 1200px) {
        display: unset;
    }

    .navbar-nav {
        margin: 15px;

        @media (min-width: 1200px) {
            margin: 0;
        }
    }

    .language-select {
        right: 15px;
        position: absolute;

        &:after {
            @media (max-width: 1200px) {
                clear: both !important;
            }
        }
    }

    li {
        &.nav-item {
            border-bottom: 1px solid $color-nav;
            padding-bottom: 5px;
            padding-top: 5px;

            @media (min-width: 1200px) {
                border-bottom: none;
                padding-bottom: 0;
                padding-top: 0;
            }

            &:first-child {
                border-top: 1px solid $color-nav;
                margin-top: 25px;
                padding-top: 5px;

                @media (min-width: 1200px) {
                    border-top:none;
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    .navbar-toggler {
        @media (max-width: 1200px) {
            color: #5a5b5c;
            box-shadow: none;
            border: none;
        }
    }

    .list-xl {
        @media (min-width: 1200px) {
            margin-right: 2rem!important;
        }
    }
}

/* fonts sizes */
.font-xs {
    @media (min-width: 992px) {
        font-size: 16px;
        line-height: 21px;
    }
}

.font-s {
    @media (max-width: 767px) {
        font-size: 16px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 13px;
        line-height: 20px;
    }

    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 23px;
    }
}

.font-m {
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
    }

    @media (min-width: 992px) {
        font-size: 20px;
        line-height: 35px;
    }
}

.font-l {
    @media (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 18px;
        line-height: 27px;
    }

    @media (min-width: 992px) {
        font-size: 22px;
        line-height: 35px;
    }
}

.font-xl {
    @media (max-width: 767px) {
        font-size: 20px;
        line-height: 25px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 20px;
        line-height: 35px;
    }

    @media (min-width: 992px) {
        font-size: 35px;
        line-height: 50px;
    }
}

.font-social-media-icon {
    @media (max-width: 768px) {
        font-size: 25px;
        line-height: 53px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        font-size: 35px;
        line-height: 58px;
    }

    @media (min-width: 992px) {
        font-size: 45px;
        line-height: 83px;
    }
}

.social-media-border {
    border: 2px solid $secondary-theme-color-darker;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
    }

    @media (max-width: 768px) {
        width: 53px;
        height: 53px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        width: 58px;
        height: 58px;
    }

    @media (min-width: 992px) {
        width: 83px;
        height: 83px;
    }
}

.font-cookies {
    @media (max-width: 767px) {
        font-size: 14px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 13px;
        line-height: 17px;
    }

    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 25px;
    }
}

h1, h2, h3, h4 {
    font-size: inherit;
}

/*header*/
.logo {
    width: 158px;

    @media (min-width: 768px) {
        width: 183px;
    }

    @media (min-width: 1200px) {
        width: 265px;
    }
}

/*banner*/
.bannerImg {
    width: 100%;
    object-fit: cover;
    height: 30vh;
}

.bannerText {
    display: block;
    position: absolute;
    text-align: center;
    top: 0;

    @media (min-width: 768px) {
        position: absolute;
        width: 608px;
        top: 12%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    @media (min-width: 1200px) {
        position: absolute;
        width: 891px;
        top: 7%;
        left: 51%;
    }
}

.banner {
    position: relative;
}

/*content*/
.form {
    z-index: 5;
}

.full_container {
    position: relative;
}

.socialMediaContainer {
    color: $secondary-theme-color-darker;
}

/*buttons and links interaction */
input {
    width: 100% !important;
    border-radius: 5px;
    border: 1px solid $secondary-theme-color-light-dark;
    font-size: inherit;
    height: unset;

    &:focus, &:active {
        outline: none;
        border-color: $primary-theme-color;
        box-shadow: none;
    }
}

textarea {
    width: 100% !important;
    border-radius: 5px;
    font-size: inherit;
    height: unset;
    outline: none;
    border-color: $primary-theme-color;
    box-shadow: none;
}

.form-control {
    width: 100% !important;
    border-radius: 5px;
    border: 1px solid $secondary-theme-color-light-dark;
    font-size: inherit;
    height: unset;

    &:focus, &:active {
        outline: none;
        border-color: $primary-theme-color;
        box-shadow: none;
    }

    &.is-invalid {
        border-color: $color-invalid;
    }
}

.button {
    border: 1px solid $primary-theme-color-dark;
    border-radius: 30px;
    margin: auto;
    display: block;
    transition: all .2s ease-in-out;

    @media (max-width: 575.98px) {
        width: 288px;
    }

    @media (min-width: 575.98px) {
        width: 384px;
    }

    @media (min-width: 1200px) {
        width: 424px;
    }

    &:focus {
        font-weight: bold;
        border: none;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
    }
}

button:disabled, .btn-disabled {
    background: transparent !important;
    border: 1px solid $color-base-light !important;
    color: $color-base-light !important;
    pointer-events: none;
}

.formButton {
    width: 100% !important;
    border-radius: 5px;
    font-size: inherit;
    height: unset;
    background-color: $color-base-light;
    color: $primary-theme-color-dark;
    border: 1px solid $color-base-light;
}

.language {
    text-transform: capitalize;
}

.social-media {
    display: inline-block !important;
}

a {
    color: inherit;
    display: initial;

    &:hover {
        display: inherit;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    &:focus {
        outline: none;
        border-color: $primary-theme-color;
        box-shadow: none;
    }

    &.logoLink {
        &:hover {
            transform: none !important;
        }
    }

    &.font-cookies {
        &.read-more {
            font-weight: bold;
        }
    }

    &.read-more {
        &:hover {
            display: initial;
            transform: unset !important;
            transition: none !important;
        }
    }

    &.acceptCookies{
        &:hover {
            cursor: pointer;
            transform: scale(1.02);
            -webkit-transition: all .2s linear;
            transition: all .2s linear;
        }
    }
}

/*cookie alert*/
#privacyCookie {
    background-color: $secondary-theme-color-light-dark;
    color: $secondary-theme-color-darker;
    display: none;
}

.cookieMessage {
    width: 100%;
    position: relative;

    @media (min-width: 768px) {
        width: 90%;
    }

    .btnContainer {
        display: flex;
        justify-content: flex-end;
    }
}

button {
    &.btn-close {
        color: $secondary-theme-color-dark;
        opacity: 1;
        border: none;
        text-shadow: none;
        background: transparent;
        font-size: 1.5rem;

        &:hover {
            color: $secondary-theme-color-dark;
            opacity: 1 !important;
        }
    }

    &:disabled {
        background: transparent !important;
        border: 1px solid $color-base-light !important;
        color: $color-base-light !important;
        pointer-events: none;
    }

    &:focus {
        outline: none;
        border-color: $primary-theme-color;
        box-shadow: none;
    }
}

.acceptButton {
    &.button {
        background-color: $secondary-theme-color-dark;
        color: $color-base-light;
        border: none;
        text-align: center;
        max-height: 55px;
        width: 253px;
    }
}

.address-container {
    border-radius: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: $primary-theme-color 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    opacity: 1;

    @media (max-width: 575.98px) {
        width: 95%;
    }

    @media (min-width: 1024px) {
        width: 810px;
    }
}

.address-block {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;

    @media (min-width: 768px) {
        width: 440px;
    }

    @media (min-width: 1024px) {
        width: 540px;
    }
}

.form-block {
    border-radius: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.form-inputs {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;

    @media (min-width: 768px) {
        width: 440px;
    }

    @media (min-width: 1024px) {
        width: 540px;
    }
}

.text-content {
    margin: auto;
    width: 90%;

    @media (min-width: 768px) {
        width: 608px;
    }

    @media (min-width: 1200px) {
        width: 1088px;
    }
}

.address-block-container {
    @media (min-width: 575.98px) {
        width: 500px;
    }

    @media (min-width: 768px) {
        width: 540px;
    }
}

#footer {
    padding-top: 20px;

    @media (min-width: 1200px) {
        width: 100%;
    }
}

.form-container {
    @media (max-width: 575.98px) {
        width: 95%;
    }

    @media (min-width: 1024px) {
        width: 810px;
    }
}

.footer-alignment {
    @media (min-width: 575.98px) {
        display: block;
    }
}

.rightsText {
    @media (min-width: 768px) {
        display: block;
    }
}

#main-content {
    @media (min-width: 1200px) {
        width: 100%;
    }
}

.card-review {
    .card-body {
        p {
            display: inline;
        }
    }
}

.navbar-expand-lg {
    .navbar-nav {
        .nav-link {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.usps-text {
    padding-right: 3.5rem;
    margin-bottom: 2.5rem;
}

//Alert message box
@import 'alert';

body {
    &.alert-message {
        margin-top: 46px;
    }
}

a {
    text-decoration: none;
}

.btn-close {
    float: right;
}
/*Custom end*/
