// It is allowed now to hide the reCAPTCHA banner, but there are requirements:
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
    visibility: hidden;
}

form {
    small.google-recaptcha-disclamer {
        font-size: 60%;

        a:hover {
            display: inline;
        }
    }

    button:disabled, .btn-disabled {
        pointer-events: none !important;
        opacity: .65;
    }
}
