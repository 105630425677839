// Body
$body-color:                        #58595B;

// Typography
$font-size-base:                    1rem;
$line-height-base:                  1.5;

// Border
$small-border-radius:               10px 0;
$medium-border-radius:              15px 0;
$large-border-radius:               25px 0;

// Colors
$primary-theme-color:               #9da67c;
$primary-theme-color-dark:          #495f1c;

$secondary-theme-color:             #A1A1A4;
$secondary-theme-color-light-dark:  #D1D1D1;
$secondary-theme-color-light:       #ECECEC;
$secondary-theme-color-lighter:     #EEEEEE;
$secondary-theme-color-dark:        #707070;
$secondary-theme-color-darker:      #474849;

$color-base-light:                  #FFFFFF;
$color-base-dark:                   #000000;

$color-invalid:                     #EB0000;
$color-btn-upload:                  #2B2B2B;
$color-btn-form:                    #a1a1a1;
$color-nav:                         #d1cece;
